$red: #bf1d2c;
// $red: rgb(197, 93, 115);
$pink-muted: #e75870;
$purple-dark: #32325d;
$yellow: #f4e87c;
$yellow-accent: #9f956c;
$diamond: #bce7fd;
$ruby: #af3b6e;
$green: #1ee182;
$grape: #c492b1;
// $light: #ebe9d0;
$light: #f7f8f9;
$light-accent: #d9d6af;
$gray: #d6ced5;
$dark: #13131d;

$primary: $purple-dark;
$primary-accent: $pink-muted;
$text-on-primary: $light;
$text-on-background: $primary;
$text-on-primary-accent: $light-accent;

//Themes
$background: $light;
