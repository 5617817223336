.input {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  overflow: visible;
  box-sizing: border-box;
  font-weight: 400;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  width: 100%;
  color: rgb(50, 50, 93);
  height: 40px;
  background-color: white;
  box-shadow: rgb(230, 235, 241) 0px 1px 3px 0px;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  border-image: initial;
  border-radius: 4px;
  outline: none;
  transition: box-shadow 150ms ease 0s;
  padding: 10px 12px;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: 16px 18px;
  background-position: 98% 50%;
  cursor: auto;
  background: white;
  &:focus {
    border: 2px solid lightblue;
  }
}
select.input:focus {
  border: initial;
}

.iconWrapper {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  text-align: start;
  padding: 14px 32px 14px 14px;
  font-size: 1rem;
  &:focus-within {
    border: 2px solid lightblue;
  }
  svg {
    font-size: 1.5rem;
  }
  input {
    background: none;
    width: 100%;
    border: none;
    outline: none;
    margin: 0;
    padding: 0;
    padding-left: 4px;
    color: inherit;
  }
}
