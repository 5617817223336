.bookingData {
  .text-success {
    font-size: 1.2rem;
  }
  .print {
    flex-basis: 100%;
    margin-top: 1rem;
    display: flex;
    justify-content: center;

    @include md {
      margin-left: 3rem;
      justify-content: flex-start;
    }
  }
  .row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .title {
    margin: 0;
    svg {
      margin-bottom: 3px;
      vertical-align: middle;
    }
    font-size: 2rem;
  }
  .bookingData_infoSection {
    @include sm {
      flex-basis: 50%;
    }
  }
  .bookingData_item {
    svg {
      margin-bottom: 3px;
      vertical-align: middle;
    }
    margin: 0.8rem auto;
    font-size: 1.2rem;
  }
  .bookingData_payment {
    margin-top: 2rem;
    @include sm {
      flex-basis: 50%;
      margin-top: 0;
    }
    max-width: 295px;
    svg {
      margin-bottom: 3px;
      vertical-align: middle;
    }
    text-align: center;
    font-size: 2rem;
    .bookingData_info {
      text-align: center;

      font-size: 1rem;
    }
    .bookingData_item {
      text-align: center;
      font-size: 1.5rem;
      font-weight: bold;
    }
    .bookingData_payment_info {
      position: relative;
      display: flex;
      align-items: center;
      .input {
        font-size: 1.5rem;
        margin: 0;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
      }
      .toolTip {
        opacity: 0;
        position: absolute;
        top: -20px;
        left: 20px;
        background: $grape;
        color: white;
        font-size: 1rem;
        padding: 4px 8px;
        border-radius: 5px;
        will-change: opacity;
        transition: opacity 0.2s ease-in-out;
        &::after {
          content: '';
          position: absolute;
          top: 100%;
          left: 50%;
          margin-left: -5px;
          border-width: 5px;
          border-style: solid;
          border-color: $grape transparent transparent transparent;
        }
        &[visible='true'] {
          transition: opacity 0.3s ease;
          opacity: 1;
        }
      }
      .copy {
        border: 0;
        font-size: 1.2rem;
        padding: 0.7rem 0.8rem;
        color: $yellow-accent;
        background: $yellow;
        display: flex;
        align-items: center;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        cursor: pointer;
      }
    }
  }
}
