.button {
  outline: none;
  font-weight: bold;
  font-size: 1.2rem;
  appearance: button;
  border: none;
  padding: 16px 32px;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px,
    rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
  transition: all 0.3s ease;
  svg {
    margin-bottom: 3px;
    vertical-align: middle;
  }
  &.primary {
    background: $primary;
    color: $text-on-primary;
  }
  &.inverted {
    background: $light;
    color: #26262b;
  }
  &.brand {
    // background-color: #e75870;
    background-color: $purple-dark;
    color: $text-on-primary;
  }
  &.accent {
    background-color: $yellow;
    color: $yellow-accent;
    box-shadow: #e6ebf1 0px 1px 3px 0px;
  }
  &.outlined {
    border: 3px solid $primary;
    background: #fff;
  }
  &:hover {
    transform: scale(0.95);
  }
  &:disabled {
    background: transparent;
    color: #fff;
    box-shadow: none;
    border: 1px solid #ccc;
    &:hover {
      transform: scale(1);
    }
  }
}
