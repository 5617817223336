.Ticket {
  margin-right: 1rem;
  margin-bottom: 0.5rem;
  padding: 1.8rem 0;
  background: $primary;
  color: $text-on-primary-accent;
  width: 250px;
  @include sm {
    width: 200px;
  }
  border-radius: 6px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  text-align: center;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $text-on-primary;
    margin-block-start: 0;
    margin-block-end: 0;
  }
  hr {
    width: 60px;
    border: 1.5px solid $light;
    border-radius: 1.5px;
  }
  .date {
    margin: 0.5rem 0;
    font-size: 1.5rem;
  }
  .button-holder {
    display: block;
    text-align: center;
    padding-top: 1rem;
  }
}
