@import './import';
*,
*:before,
*:after {
  box-sizing: border-box;
}
body {
  margin: 0;
  padding-top: 48px;
  font-family: 'Roboto', sans-serif;
  overflow-x: hidden;
  background-color: $background;
  color: $text-on-background;
}

h1,
h2,
h3,
h4 {
  font-family: 'Roboto Slab', serif;
  color: $text-on-background;
}
p {
  color: $text-on-background;
}
a {
  text-decoration: none;
  color: inherit;
}
.container {
  margin-bottom: 3rem;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
hr.break {
  border: 1px solid rgba($color: #000000, $alpha: 0.08);
}
@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

.text-center {
  text-align: center;
}
.text-success {
  color: $green;
}
.mx-auto {
  margin: 0 auto;
}

.hide-on-md-and-up {
  @include md {
    display: none !important;
  }
}
.hide-on-lg-and-up {
  @include lg {
    display: none !important;
  }
}
.hide-on-md-and-down {
  display: none !important;
  @include lg {
    display: block !important;
  }
}
.mt-1 {
  margin-top: 1rem;
}
.mt-2 {
  margin-top: 2rem;
}
.mt-3 {
  margin-top: 3rem;
}

.hasIcon {
  svg {
    vertical-align: middle;
    margin-bottom: 3px;
  }
}
.u-full-width {
  width: 100%;
}

@media print {
  .no-print {
    display: none !important;
  }
}
